<template>
  <div class="flex flex-col w-full">

    <div class="flex flex-col lg:flex-row lg:flex-wrap ">
      <div v-for="(category, index) in faqCategories" :key="index" class="w-full lg:w-4/12 p-2 lg:p-8">
        <div class="shadow-lg-dark bg-white shadow-blue-400 h-auto lg:h-60 hover:shadow-2xl">
          <h3 class="text-2xl font-semibold p-5"> {{ category['title'] }}</h3>
          <div class="border-t-2">
          </div>

          <div class="p-5">
            <div class="flex flex-wrap">
              {{ category['description'] }}
            </div>

            <button
                class="border border-gray-900 text-center text-sm px-5 py-2 mt-5 uppercase hover:bg-blue-300
                 hover:border-blue-800 hover:text-blue-700 text-gray-600" @click="faqCategory(category['id'])">
              {{ category['button_text'] }}
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="flex flex-row lg:pl-20 py-6 text-base lg:text-3xl">FairPY Investment Q&A Session Nov 11 2022</div>
    <div class="flex justify-center mb-12">
      <!--      <iframe width="1000" height="400" src="https://www.youtube.com/watch?v=Zm76CyfrOGg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

      <iframe width="1000" height="400" src="https://www.youtube.com/embed/Zm76CyfrOGg" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>

  </div>
</template>

<script>

export default {
  name: "Faq",
  components: {},
  data() {
    return {
      faqCategories: ''
    };
  },
  mounted() {
    this.loadFaqs();
  },
  methods: {
    loadFaqs() {
      this.$store.dispatch("investorsFaqCategories").then(
          (res) => {
            this.faqCategories = res.data.data
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    faqCategory(id) {
      this.$router.push({name: "FaqCategory", params: {id: id}});
    },
  },
};
</script>

<style></style>
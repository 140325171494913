<template>
  <TopBar />
  <DefaultBanner
      title="Frequently Asked Questions"
      :class="['lg:faq-banner-bg faq-banner-mobile-bg']"
  />
  <div class="w-100 relative overflow-hidden">
    <div
        class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
          class="lg:w-auto w-full absolute left-0 top-0 hidden lg:block"
          src="/images/map-right.png"
      />
      <img
          class="lg:w-auto w-1/2 absolute left-0 top-0 lg:hidden"
          src="/images/map-right.png"
      />
    </div>
    <div
        class="
        container
        mx-auto
        lg:px-3
        px-5
        py-6
        z-10
        relative
        flex flex-row
        items-stretch
        lg:mb-12
      "
    >
      <FaqList />
    </div>
  </div>
  <Footer />
</template>

<script>
import TopBar from "@/views/investors/components/TopBar";
import Footer from "@/components/Footer.vue";
import DefaultBanner from "@/components/DefaultBanner";
import FaqList from "@/views/investors/components/FaqList";


export default {
  name: "Faq",
  components: {
    TopBar,
    DefaultBanner,
    FaqList,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style></style>